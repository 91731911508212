.sidebar .ant-collapse {
  border-radius:0;
}

.sidebar .ant-collapse>.ant-collapse-item {
  border-radius:0;
}
.sidebar .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  border-radius:0;
}


.ant-collapse>.ant-collapse-item >.ant-collapse-header {
  display:flex;
  align-items: center;
  padding-right:0;
  padding-block:0;
}
.ant-collapse-header-text {
  display:inline-flex;
  align-items: center;

  & .title {
    display:inline-flex;
    flex-grow:1;
    user-select: none;
  }
  
  & .item-actions {
    display:inline-flex;
    gap:0.25em;
    padding-right:0.25em;
    padding-block:4px;

    & button {
      font-size:0.9em;
      padding-inline: 0.8em;
    }
  }
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box {

  padding:4px;

}