main {
  position: relative;
  flex: 1;

  display: flex;
  align-items: stretch;

  overflow:hidden;
}

.project-view {
  height: 100vh;
  overflow: hidden;
}
