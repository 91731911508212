html {
  background-color: #f5f5f5;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height:100vh;
  overflow-x:hidden;
}

#app {
  flex: 1;
  display: flex;
  flex-direction: column;
  width:100%;
}

.loading {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
