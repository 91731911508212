.editor-resizer {
  position: absolute;
  left: 0;
  bottom: 5vh;
  z-index:100000;

  .handle {
    cursor: col-resize;

    position: relative;
    left: -1.5rem;
    display: block;
    width: 3rem;
    height: 2rem;
    background-color: #eee;
    border-radius: 0.25rem;

    font-size: 2rem;

    .anticon {
      display: block;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50%), -50%);
    }
  }
}
